@import "./utilities.scss";
@import "./variables.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/*
  |--------------------------------------------------------------------------
  |  Typography
  |--------------------------------------------------------------------------
 */

/* // Primary font */
$primary-font: Roboto, sans-serif;
$primary-font-bold: "Roboto Bold", sans-serif;
$primary-font-italic: "Roboto Italic", sans-serif;

/* // Secondary font */
$secondary-font-heavy: "CaeciliaLTStd-Heavy", serif;
$secondary-font-bold: "CaeciliaLTStd-Bold", serif;

/*
  |--------------------------------------------------------------------------
  |  End of Typography
  |--------------------------------------------------------------------------
*/

/*layout*/

html {
  overflow-y: hidden;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.table-outer-border {
  border: 1px solid $light-grey2;
  border-radius: 5px;
  padding: 10px;
  margin-top: 0.5rem;
}

.content-wrapper {
  min-height: 64vh;
  border-radius: 5px;
  border: 1px solid $light-grey2;
  padding: 10px;
  margin-top: 20px;
  @media screen and (min-width: 1370px) {
    min-height: 70vh;
  }
}

.footer-actions {
  justify-content: flex-end;
  display: flex;

  button {
    margin-left: 10px;
  }
}

.body-full,
.body-wfooter,
.body {
  overflow-x: hidden;
  min-height: 290px;

  .has-Actions {
    padding-bottom: 35px;
  }
}

.body {
  height: 65vh;
  overflow-y: auto;

  @media screen and (min-width: 1400px) {
    height: 72vh;
  }

  @media screen and (max-height: 767px) {
    height: 58vh;
  }
}

.body-full {
  min-height: 78vh;
  max-height: 78.1vh;
  overflow-y: auto;

  @media screen and (max-height: 767px) {
    min-height: 72.4vh;
    max-height: 72.5vh;
  }

  @media screen and (min-height: 900px) {
    min-height: 80vh;
    max-height: 83.5vh;
  }
}

.body-wfooter {
  max-height: 60vh;
  min-height: 59.9vh;
  overflow-y: auto;
  padding-right: 5px;

  @media screen and (max-height: 767px) {
    min-height: 47.5vh;
    max-height: 47.5vh;
  }

  @media screen and (min-height: 900px) {
    min-height: 69vh;
    max-height: 70vh;
  }
}

.body-footer {
  height: 30px;
  margin-top: 2px;
}

.flex-wrap {
  display: flex;
}

.sl-equal-col {
  flex: 1;
  margin-right: 10px;
}

.sl-equal-col:last-child {
  margin-right: 0;
}

/** TOOLTIP / POPOVER **/

.popup-status-title {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.popup-style ul {
  margin: 0;
  padding: 0;
}

.popup-style ul li {
  list-style: none;
  margin: 7px 0;
}

.btn-edit {
  font-family: $primary-font-bold;
  color: $core-green;
  cursor: pointer;
}

.btn-edit:after {
  content: " Editar";
}

/*** Tipografia */

p {
  font-size: 12px;
}

.title {
  font-size: 16px;
  font-family: $primary-font-bold;
  margin-bottom: 20px;
}

.title-light {
  font-size: 16px;
  margin-bottom: 20px;
}

.txtlabel {
  font-size: 10px;
  font-family: $primary-font-bold;
}

@media screen and (min-width: 1920px) {
}

/* ./ Tipografia */

/** buttons */
.btn-sl {
  border-radius: 10px;
  color: $white;
  text-align: center;
  font-family: $primary-font-bold;
  border: none;
  font-size: 12px;
  padding: 8px 18px 8px 18px;
  cursor: pointer;
}

.btn-sl:disabled {
  cursor: not-allowed;
  background: $light-grey4 !important;
  color: $btn-disabled-color !important;
}

.btn-sl-grey {
  color: $primary-color !important;
  background: $light-grey4;
}

.btn-sl-red {
  background: linear-gradient(180deg, #dc2f37 0%, #ac1018 100%);
}

.btn-sl-green {
  background: $core-green;
}

.btn-select-files {
  margin-right: 5px;
  width: 100%;
}

.btn-white {
  border: 1px solid $primary-color;
  color: $primary-color;
  background: $white;
  padding: 8px 18px 8px 18px;
  margin: 10px;
}

.btn-text-green {
  padding: 8px;
  background: none;
  border: none;
  font-family: $primary-font-bold;
  color: $core-green;
  cursor: pointer;
}

.btn-new-content-grid {
  display: flex;
}

.ant-btn:hover,
.ant-btn:focus {
  color: $white;
  border-color: $white;
}

/** /. buttons */

/** Modal & form */
::ng-deep .ant-modal-content {
  font-family: $primary-font;
}

::ng-deep .ant-modal-title,
::ng-deep .ant-modal-confirm-title {
  font-size: 20px !important;
  font-family: $primary-font-bold !important;
  color: $dark-grey1;
}

::ng-deep .ant-modal-content,
::ng-deep .ant-modal-header {
  border-radius: 8px;
}

::ng-deep .ant-modal-close-icon {
  color: $core-green;
}

.ant-form-item-label {
  text-align: left;
}

.btn-cancel {
  margin-right: 5px;
  padding: 8px 50px 8px 50px;
}

.btn-save {
  padding: 8px 50px 8px 50px;
}

::ng-deep .ant-select {
  height: 26px !important;
}

::ng-deep .ant-select-selection-item {
  line-height: 20px !important;
  height: 21px !important;
  font-size: 12px !important;
  margin-top: 0 !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: $dark-grey1;
  font-size: 20px;
  font-weight: 700;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  padding-top: 6px;
  color: $light-grey3;
  font-size: 12px;
  font-weight: 400;
  margin-top: 0 !important;
}

::ng-deep .ant-input-borderless,
.ant-select {
  border-bottom: 0 solid $light-grey2;
  font-size: 12px;
}

::ng-deep .ant-input-borderless:hover,
.ant-select:hover,
::ng-deep .ant-input-borderless:focus {
  border-bottom: 0 solid $dark-grey4;
}

::ng-deep
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ).ant-select-selector {
  border: none;
}

textarea.ant-input:hover {
  border: 1px solid $dark-grey4;
}

.ant-select {
  width: 100%;
}

.ant-descriptions-item-label,
.sl-form-label {
  font-size: 11px;
  font-family: $primary-font;
  color: $dark-grey4;
}

.sl-form-control {
  border-bottom: 1px solid $light-grey2;
}

.sl-form-item {
  margin-bottom: 10px;
  min-height: 40px;
}

.form-col {
  padding-right: 10px;
}

::ng-deep .ant-select-arrow .anticon {
  color: $primary-color;
}

::ng-deep
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0;
  height: 26px;
}

::ng-deep
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 26px;
}

.checkbox-label {
  display: flex;
}

.checkbox-label input {
  margin-right: 15px;
}

::ng-deep #btnUpArquivoV2Additional .ant-upload.ant-upload-drag {
  margin-right: 15px;
  background: none;
  width: 100%;
  background-color: $sidebar-gray;
  border: 1px dashed $light-grey1;
  border-radius: 8px;
  height: 90px;
}

::ng-deep #btnUpArquivoV2Additional .ant-upload-drag-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

::ng-deep #btnUpArquivoV2Additional .ant-upload-btn {
  display: flex;
  padding-left: 15px;
}
::ng-deep #btnUpArquivoV2Additional .ant-upload-list-item-name {
  color: #007e7a;
  font-weight: 700;
  font-size: 12px;
}

::ng-deep #btnUpArquivoV2Additional p.ant-upload-drag-icon {
  margin-bottom: 0;
}

::ng-deep
  #btnUpArquivoV2Additional
  .ant-upload-list-item-card-actions.picture
  .ant-upload-list-item-card-actions-btn {
  font-size: 17px;
}

::ng-deep .ant-upload.ant-upload-drag {
  margin-right: 15px;
  background: none;
  width: 50%;
  background-color: $sidebar-gray;
  border: 1px dashed $light-grey1;
  border-radius: 8px;
}

.uploadProjectFiles {
  display: flex;
  max-width: 100%;
}

.uploadProjectFilesMeiaLargura {
  display: flex;
  max-width: 50%;
}

::ng-deep .ant-picker-input > input {
  font-size: 12px;
}

::ng-deep .ant-form-item-control-input {
  min-height: 25px;
}

textarea.ant-input {
  border-radius: 8px;
  font-size: 12px;
  font-family: $primary-font;
}

.ant-modal-header {
  border-bottom: 0;
}

.ant-modal-footer {
  border-top: 0;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  height: 47px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 24px;
  height: 30px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 24px;
  height: 30px;
}

.ant-upload-list-item {
  margin-top: 0;
}

.ant-upload-list-item-name {
  font-weight: bold;
}

.ant-upload-list-item-card-actions .anticon {
  color: $core-green;
  font-size: 17px;
}

.ant-upload-list-item-card-actions .anticon {
  color: $core-green;
  font-size: 17px;
}

.ant-upload-list-item-card-actions:hover .anticon-download {
  color: rgba(0, 0, 0, 0);
}

.ant-upload-list-item-card-actions .anticon-download {
  color: rgba(0, 0, 0, 0);
}

::ng-deep .ant-radio-inner::after {
  background: linear-gradient(180deg, $light-green2 0%, $core-green 100%);
}

::ng-deep .ant-radio-wrapper:hover .ant-radio,
::ng-deep .ant-radio:hover .ant-radio-inner,
::ng-deep .ant-radio-input:focus + .ant-radio-inner {
  border-color: $light-grey2;
}

::ng-deep .ant-upload-list {
  width: 50%;
}

::ng-deep label::after {
  content: "" !important;
}

.ant-switch-checked {
  background: $core-green;
}

.errorHint {
  color: $red;
  padding: 6px 0;
  font-size: 11px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary-color;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary-color;
  box-shadow: 0 0 0 2px $primary-color-shadow;
}

::ng-deep .ant-input-number-borderless {
  width: 100%;
  height: 28px;
  border-radius: 0;
  border-bottom: 0 solid $light-grey2;
}

::ng-deep .ant-input-number-borderless,
::ng-deep .ant-input-number-borderless:hover,
::ng-deep .ant-input-number-borderless:focus,
::ng-deep .ant-input-number-borderless-focused,
::ng-deep .ant-input-number-borderless-disabled,
::ng-deep .ant-input-number-borderless[disabled] {
  border-radius: 0;
  border-bottom: 0 solid $light-grey2;
}

.disabled-borderBottom {
  border-bottom: 1px solid $light-grey2;
}

.gridSelectOption {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus,
  &:focus-visible {
    border: 1px solid #eeeeee;
  }
}

/** Modal & form */
::ng-deep .ant-modal {
  .ant-modal-confirm {
    color: $core-green;
  }

  .ant-modal-confirm-body {
    > .anticon {
      display: none;
    }

    .ant-modal-confirm-content {
      margin-left: 0 !important;
      padding-top: 6px !important;
    }
  }

  .ant-modal-confirm-btns button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $dark-grey4;
    background: $white;
    border-radius: 8px;
    border: 1px solid $dark-grey4;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
  }

  .ant-modal-confirm-btns > .ant-btn-primary {
    background-color: $primary-red;
    color: $white;
    border: none;
    border-radius: 8px;
  }
}

/** scroll **/
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/** Colapse Pannel **/
::ng-deep .ant-collapse-header {
  padding: 12px 8px 12px 0 !important;
}

::ng-deep .ant-collapse-content-box {
  padding: 0 !important;
}

/** margins **/

.mt-05 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

/** paddings **/
.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

/** aligns **/
.ali-right {
  text-align: right;
}

::ng-deep.ant-upload-list {
  max-width: 48%;
}

.cursor-pointer {
  cursor: pointer;
}

/**/
.logLink {
  text-decoration: underline !important;
  color: $core-green !important;
}

.lbl-new-content {
  color: var(--Core-Green-Vale, #007e7a);
  font-family: $primary-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
  cursor: pointer;
}

/* Side Drawer */
::ng-deep .ant-drawer-title {
  font-weight: bolder;
  font-size: 16px;
}

::ng-deep .ant-drawer-close {
  position: absolute;
  margin-right: 12px;
  color: #007e7a !important;
  font-weight: bolder;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
  right: 10px !important;
}

::ng-deep .ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}

::ng-deep .ant-drawer-body {
  padding-left: 15px;
  padding-right: 20px;
}



//PRIME-NG

.p-disabled {
  .p-button {
    .p-button-label {
      color: #969696 !important;
    }
  }
}
.p-button {
  border: none;
  cursor: pointer;
  &:focus{
    box-shadow: none;
  }
  &:hover{
    background: transparent !important;
  }

  .p-button-label {
    font-size: 14px;
  }

  .p-button-icon {
    color: #007e7a !important;
  }
  .p-button-icon-14px {
    font-size: 0.8rem;
  }
  &.p-disabled {
    background: $white !important;
  }
}

.sl-badge {
  font-size: 12px !important;
}

.p-tooltip {
  color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  max-width: 15.5rem !important;
}

.p-tooltip-arrow {
  border-top-color: #333333;
}

.p-tooltip .p-tooltip-text {
  background-color: #333333;
}

.p-component {
  font-size: 12px !important;
}

.ant-form-horizontal {
  flex: none;
}

.p-dropdown  {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  font-size: 12px;
  font-family: $primary-font;
  .p-dropdown-label {
    font-size: 12px !important;
    padding: 8px !important;
    @media screen and (min-width: 1440px) {
      font-size: 14px !important;
    }
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    font-size: 12px !important;
    padding: 8px !important;
    
    @media screen and (min-width: 1440px) {
      font-size: 14px !important;
    }    
  }
  &.p-disabled, .p-disabled * {
    cursor: default !important;
    background: #eeeeee;
    pointer-events: none;
    .p-dropdown-trigger{
      display: none !important;
      .p-dropdown-trigger-icon{
        display: none !important;
      }
    }
    .p-icon-wrapper {
      display: none !important;
    }
    .p-dropdown-trigger-icon {
      display: none !important;
    }
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: $light-grey3;
  }
  &:hover {
    border-color: $light-grey3;
  }
}
// .p-disabled, .p-disabled * {
//   background: $white
// }

.p-dropdown:not(.p-disabled).p-focus {
  border-color: $light-grey3;
  box-shadow: none !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $light-grey4 !important;
  color: $dark-grey2;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: $light-grey3;
}
.p-inputtext {
  font-size: 12px;
  font-family: $primary-font;
  width: 100%;
  &:hover {
    border-color: $light-grey3;
  }
  @media screen and (min-width: 1440px) {
    font-size: 14px !important;
  }
}

.p-tabview{ 
  a {
    &.p-tabview-title{
      color: #007e7a !important;
      font-family: $primary-font-bold !important;
    }
    color: black !important;
  }
  a:hover {
    color: #007e7a !important;
    border-color: #007e7a !important;
  }
  .p-tabview-nav  li.p-highlight .p-tabview-nav-link {
    border-color: #007e7a !important;
    font-family: $primary-font-bold !important;
    color: #007e7a !important;
  }
  .p-tabview-nav{
    font-family: $primary-font-bold !important;
    color: #007e7a !important;
  }

  .p-tabview-panels {
    font-family: $primary-font-bold !important;
    padding: 0.75rem 0.25rem;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  line-height: 50px !important;
  padding: 0px !important;
}
.p-datatable .p-datatable-tbody > tr {
  line-height: 50px !important;
}

.p-datatable .p-sortable-column .p-icon-wrapper {
  display: inline;
  float: right !important;
}

.p-sidebar {
  position: fixed;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  max-height: 98%;
  width: 66%;
  padding-top: 16px;
  margin-top: 1%;
  border-radius: 8px 0px 0px 0px;
  &.p-sidebar-header{
    display: none !important;;
  }

}
.p-sidebar-header {
  display: none !important;
}

.p-sidebar-right {
  top: none !important;
}

::ng-deep .cdk-overlay-container{
  z-index: 3000;
}

.p-calendar {
  width: 100%;
  input {
    padding: 8px;
  }
}

.p-datepicker table td.p-datepicker-today>span {
  background-color: $primary-color !important;
  color: $white !important;
}

// Utilizado no p-columnFilter checkbox
::ng-deep p-checkbox {
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover  {
    border-color: $primary-color;
    background: $primary-color;
    color: $white;
  } 

}

// Utilizado no p-columnFilter checkbox
::ng-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: $primary-color;
  background: $primary-color;
  color: $white;
}

// Utilizado no p-columnFilter checkbox
::ng-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $primary-color;
}

// Utilizado no p-columnFilter checkbox
::ng-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: $primary-color;
}

// Utilizado no p-columnFilter
::ng-deep .p-multiselect:hover {
  border-color: $light-grey3;
  box-shadow: none;
}

// Utilizado no p-columnFilter
::ng-deep .p-multiselect {
  border-color: $light-grey13;
  box-shadow: none;
  outline: none;
}

// Utilizado no p-columnFilter do primeNG
::ng-deep .p-column-filter {
  .p-icon {
    width: 12px;
  }
}

// Utilizado no <p-overlayPanel> filtro da tabela
::ng-deep .p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

// Utilizado no modal do p-columnFilter do primeNG
::ng-deep .p-column-filter-buttonbar {

  .p-button:first-child {
    background: transparent;
    color: $dark-grey4;
  }

  .p-button {
    background-color: $primary-color;
    color: $white;
  }

  .p-button:hover { 
    background-color: $primary-color;
    color: $white;
  }

  .p-button {
    &:focus{
      box-shadow: none;
    }
    &:hover{
      background: $primary-color !important;
    }
  }

  .p-button-outlined {
    &:focus{
      box-shadow: none;
    }
    &:hover{
      background: transparent !important;
      color: $dark-grey4;
    }
  }
  
}

// Padronizando cor do p-columnFilter calendar
::ng-deep .p-column-filter-constraint {
  .p-inputtext {
    border-color: $light-grey13;
    box-shadow: none;
    outline: none;
  }

  .p-inputtext:hover {
    border-color: $light-grey13;
    box-shadow: none;
    outline: none;
  }
}

::ng-deep .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 .2rem $light-green6 !important;
}

::ng-deep .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 .2rem $light-green6 !important;
}
